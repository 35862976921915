import { useState } from 'react'
import { XMarkIcon, PlusCircleIcon } from '@heroicons/react/24/outline'

export default function CardModal({ data }) {

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className='overflow-hidden bg-white shadow rounded-lg col-span-3 sm:col-span-2 px-4 py-5 sm:px-6 flex flex-wrap flex-row items-center justify-center gap-2'>

            <h3 className='text-md font-medium leading-8'>{data.Title}</h3>

            <button
                className='ml-auto'
                onClick={() => setIsModalOpen((prev) => !prev)}
            >
                <PlusCircleIcon className='h-8 w-8' />
            </button>

            <div className={(isModalOpen
                ? 'flex'
                : 'hidden')
                + ' overflow-hidden fixed h-full w-full flex-wrap items-center justify-center top-0 left-0 bg-gray-800/50 px-8 md:px-20 z-50'}
            >

                <div className='bg-white shadow rounded-lg flex flex-col w-auto md:w-1/2'>
                    <div className='flex flex-row items-center justify-between p-4 border-b border-gray-200'>

                        <h5 className='text-xl font-medium leading-normal'>
                            {data.Title}
                        </h5>

                        <button>
                            <XMarkIcon
                                onClick={() => setIsModalOpen((prev) => !prev)}
                                className='h-6 w-6' />
                        </button>

                    </div>
                    <div className='p-4'> {data.Content} </div>

                </div>

            </div>
        </div >
    )
}

