import { useState } from 'react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import logo from '../../assets/img/navbar/navbar_logo.png'

export default function About({ sections }) {

    const [isNavOpen, setIsNavOpen] = useState(false);

    return (
        <header className='fixed bg-white top-0 left-0 right-0 z-10 shadow'>

            <nav className='px-0 md:px-20 py-3 flex flex-wrap flex-col md:flex-row items-center'>

                <a href='#about'>
                    <img className='h-14'
                        src={logo}
                        alt='ChiliWarehouse navbar logo' />
                </a>

                <div
                    className={(isNavOpen
                        ? 'w-full h-screen bg-white flex flex-wrap flex-col md:flex-row items-center justify-center text-2xl leading-[3]'
                        : 'hidden')
                        + ' md:w-fit md:h-full md:ml-auto md:py-1 md:flex md:flex-wrap md:items-center md:text-sm'}
                >
                    {sections.map(function (Component, i) {
                        return (
                            <a
                                key={i}
                                href={'#' + Component.Tag}
                                className='md:ml-4 hover:text-red-900'
                                onClick={() => setIsNavOpen((prev) => !prev)}
                            >
                                {Component.Name}
                            </a>
                        )
                    })}
                </div>

                <div className='flex md:hidden'>
                    <button
                        className='absolute top-0 right-0 p-7'
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                        {isNavOpen
                            ? <XMarkIcon className='h-6 w-6' />
                            : <Bars3Icon className='h-6 w-6' />}
                    </button>
                </div>

            </nav>

        </header>
    )
}