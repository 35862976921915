import CardModalImg from '../CardModalImg.js';

export default function Work({ data }) {
    return (
        <div className='h-screen bg-gray-100 py-28 px-8 md:px-20'>
            <h2 className='text-3xl leading-7'>
                {data.Name}
            </h2>

            <div
                className='grid grid-cols-3 md:grid-cols-4 gap-4 mx-auto
                           py-10 h-full'
            >

                {data.Images.map(function (image, i) {
                    return (
                        <CardModalImg
                            key={i}
                            img={image}
                            alt={'work' + i}
                        />
                    )
                })
                }

            </div>
        </div >
    )
}

