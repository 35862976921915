import { useState } from 'react'

export default function CardModalImg({ img, alt, classExtra }) {

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div
            className={
                'block overflow-hidden bg-white shadow rounded-lg '
                + classExtra}
        >
            <button className='w-full h-full'>
                <img
                    className='w-full h-full object-cover'
                    src={img}
                    alt={alt}
                    onClick={() => setIsModalOpen((prev) => !prev)}
                />
            </button>

            <button
                className={(isModalOpen
                    ? 'flex'
                    : 'hidden')
                    + ' overflow-hidden fixed h-full w-full flex-wrap items-center justify-center top-0 left-0 bg-gray-800/50 px-8 py-28 md:px-20 z-50'}
                onClick={() => setIsModalOpen((prev) => !prev)}
            >
                <div className='bg-white shadow rounded-lg p-4'>
                    <img
                        className='object-contain h-[60vh]'
                        src={img}
                        alt={alt}
                    />

                </div>
            </button>

        </div>
    )
}

