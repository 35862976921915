import React, {
    useRef,
    useEffect,
    useState,
    Children,
    isValidElement,
    cloneElement
} from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

export default function GoogleMap({ map_api_key, map_pos, map_zoom }) {

    const Map = ({ children }) => {
        const ref = useRef(null);
        const [map, setMap] = useState();

        useEffect(() => {
            if (ref.current && !map) {
                setMap(new window.google.maps.Map(ref.current, {
                    center: map_pos,
                    zoom: map_zoom,
                    disableDefaultUI: true,
                }));
            }
        }, [ref, map]);

        return (
            <>
                <div ref={ref} />
                {Children.map(children, (child) => {
                    if (isValidElement(child)) {
                        return cloneElement(child, { map });
                    }
                })}
            </>
        );

    };

    const render = (status) => {
        console.log(Status.LOADING);
        switch (status) {

            case Status.FAILURE:
                return <h3>ERRORRR ..</h3> // NO FUNCIONA :(
            case Status.SUCCESS:
                console.log(Status);
                //return <MyMapComponent />;
                return null;
            case Status.LOADING:
            default:
                //return <Spinner />;
                console.log(Status);
                return <h3>{status} ..</h3>
        }
    }

    const Marker = (options) => {
        const [marker, setMarker] = useState();

        useEffect(() => {
            if (!marker) {
                setMarker(new window.google.maps.Marker());
            }

        }, [marker]);

        useEffect(() => {
            if (marker) {
                marker.setOptions(options);
            }
        }, [marker, options]);

        return null;
    };

    return (
        <Wrapper apiKey={map_api_key} render={render} >
            <Map center={map_pos} zoom={map_zoom} >
                <Marker position={map_pos} />
            </Map>
        </Wrapper>
    )
}

