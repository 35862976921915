import CardModal from '../CardModal.js';
import CardModalImg from '../CardModalImg.js';
import about1 from '../../assets/img/about/about_1.png';

export default function About({ data }) {
    return (
        <div className='h-screen bg-gray-100 py-28 px-8 md:px-20'>
            <h2 className='text-3xl leading-7'>
                {data.Name}
            </h2>
            <div className='grid grid-cols-3 gap-4 mx-auto py-10 h-full'>
                <CardModalImg
                    img={about1}
                    alt='about'
                    classExtra='row-span-4 !hidden sm:!block'
                />
                {data.Cards.map(function (card, i) {
                    return (
                        <CardModal
                            key={i}
                            data={card}
                        />
                    )
                })}
            </div>
        </div >
    )
}

