export default function Wrapper({ sections }) {
    return (
        <article
            className='snap-y snap-mandatory h-screen overflow-scroll scroll-smooth'
        >
            {sections.map(function (Section, i) {
                return (
                    <section
                        key={i}
                        id={Section.Tag}
                        className='snap-center snap-always h-screen'
                    >
                        <Section.Component data={Section} />
                    </section>
                )
            })}
        </article>
    )
}