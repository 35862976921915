import React from 'react';
import Navbar from './components/sections/Navbar.js';
import Wrapper from './components/sections/Wrapper.js';
import Home from './components/sections/Home.js';
import About from './components/sections/About.js';
import Video from './components/sections/Video.js';
import Work from './components/sections/Work.js';
import Contact from './components/sections/Contact.js';

const work_images = require.context(
  './assets/img/work',
  false,
  /\.(png|jpe?g|svg)$/
);

const active_sections = [
  {
    'Name': 'Inicio',
    'Tag': 'home',
    'Component': Home,
  },
  {
    'Name': 'Sobre nosotros',
    'Tag': 'about',
    'Component': About,
    'Cards': [
      {
        'Title': 'Técnicas y materiales',
        'Content': 'Somos un taller de serigrafía especializado en textil, pero trabajamos varios tipos de materiales. También usamos otras técnicas de impresión como vinilo o sublimación.'
      },
      {
        'Title': 'Diseño',
        'Content': 'Nuestro servicio de diseño gráfico permitirá que el pedido se ajuste lo máximo posible a las peticiones del cliente, orientándolo en todo momento para que el acabado final sea el mejor y el más adecuado.'
      },
      {
        'Title': 'Clientes',
        'Content': 'Hacemos impresiones para merchandising, marcas, grupos, peñas, uso personal, celebraciones, eventos, fiestas, publicidad, uniformes, vestuario laboral, etc. Trabajamos para particulares o empresas.'
      },
      {
        'Title': 'Tiempos de entrega',
        'Content': 'Los pedidos se realizarán siempre por orden de entrada. Se estimará un plazo de entrega aproximado a cada pedido, dependiendo del volumen de trabajo y de la dificultad del pedido en cuestión. Normalmente, el tiempo de entrega ronda las 2 semanas.'
      }
    ]
  },
  {
    'Name': 'Video',
    'Tag': 'video',
    'Component': Video
  },
  {
    'Name': 'Trabajos realizados',
    'Tag': 'work',
    'Component': Work,
    'Images': work_images.keys().map(work_images)
  },
  {
    'Name': 'Contacto',
    'Tag': 'contact',
    'Component': Contact,
    'Map': {
      'api_key': 'AIzaSyBSQ2IM6KMCkedDqo_IH1p75F4iPVwpVyA',
      'pos': {
        lat: 40.45751112980966,
        lng: -3.475711037917786
      },
      'zoom': 12
    }
  }
];

export default function App() {
  return (
    <main>
      <Navbar sections={active_sections} />
      <Wrapper sections={active_sections} />
    </main>
  );
}