import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import GoogleMap from '../GoogleMap.js';
import {
    EnvelopeIcon, PhoneIcon, MapPinIcon
} from '@heroicons/react/24/outline';
import {
    ReactComponent as InstagramIcon
} from '../../assets/img/contact/instagram_icon.svg';
import {
    ReactComponent as WhatsappIcon
} from '../../assets/img/contact/whatsapp_icon.svg';

export default function Contact({ data }) {

    const map_api_key = data.Map.api_key;
    const map_pos = data.Map.pos;
    const map_zoom = data.Map.zoom;



    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'hola@chiliwarehouse.com',
            'template_1',
            form.current,
            'BrLTk96deceamlCC-'
        )
            .then((result) => {
                console.log(result.text); // Mostrar aviso visual web
            }, (error) => {
                console.log(error.text); // Mostrar aviso visual web
            });
    };



    return (
        <div className='h-screen bg-gray-100 py-28 px-8 md:px-20'>

            <h2 className='text-3xl leading-7'>
                {data.Name}
            </h2>

            <div className='mx-auto py-10 h-full'>

                <div className='overflow-hidden bg-white shadow rounded-lg  grid grid-cols-1 md:grid-cols-2 h-full'>

                    <div className='p-8'>

                        <form
                            className='flex flex-col justify-center gap-3'
                            ref={form}
                            onSubmit={sendEmail}
                        >
                            <div>
                                No dudes en contactarnos para obtener un presupuesto o para que podamos resolver tus dudas
                            </div>
                            <input
                                className='border-2 p-2 rounded'
                                type='text'
                                name='user_name'
                                title='Introduce aquí tu nombre completo'
                                placeholder='Nombre'
                                required
                            />
                            <input
                                className='border-2 p-2 rounded'
                                type='email'
                                name='user_email'
                                title='Introduce aquí tu correo electrónico'
                                placeholder='Email'
                                required
                            />
                            <textarea
                                className='border-2 p-2 rounded h-28 resize-none'
                                name='message'
                                title='Introduce aquí tu mensaje'
                                placeholder='Mensaje'
                                required
                            />
                            <button
                                className='flex-none w-1/4 border-2 p-2 rounded
                            text-white bg-red-800 hover:bg-red-900 border-red-800 hover:border-red-900'
                                type='submit'
                            >
                                Enviar
                            </button>


                        </form>


                        <hr className='my-10 w-3/4 mx-auto' />

                        <EnvelopeIcon className='h-8 w-8' />
                        <PhoneIcon className='h-8 w-8' />
                        <WhatsappIcon className='h-9 w-9' />

                        <InstagramIcon className='h-9 w-9' />
                        <MapPinIcon className='h-8 w-8' /> Torrejón de Ardoz, Madrid, España



                    </div>




                    <GoogleMap
                        map_api_key={map_api_key}
                        map_pos={map_pos}
                        map_zoom={map_zoom}
                    />


                </div>
            </div >
        </div >
    )
}

