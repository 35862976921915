export default function Video() {
    return (
        <iframe
            className='w-full h-full'
            src='https://www.youtube.com/embed/NkPtT94UQfg?&modestbranding=1&rel=0&cc_load_policy=1&iv_load_policy=3&controls=0&disablekb=1'
            title='ChiliWarehouse video'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
        ></iframe>
    )
}