import bg from '../../assets/img/home/home_bg.jpeg'
import logo from '../../assets/img/common/logo.png'

export default function Home() {
    return (
        <div className='bg-black'>
            <div
                style={{ backgroundImage: `url(${bg})` }}
                className='h-screen bg-no-repeat bg-cover bg-top'
            >
                <div className='backdrop-blur h-screen w-screen flex'>
                    <img
                        className='min-h-1/2 w-3/4 h-auto md:h-1/2 md:w-auto inline-block m-auto'
                        src={logo}
                        alt='ChiliWarehouse logo'
                    />
                </div>
            </div>
        </div>
    )
}

